<template>
  <div>
    <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
  <v-app id="inspire">
      <v-navigation-drawer v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template>
          <router-link to="/home" class="sidebar-link">
           <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           </router-link>
         <v-divider></v-divider>
        
        </template>
      </v-list>
    </v-navigation-drawer>
     <eps-header :drawer="$store.state.drawer"></eps-header>
    <v-content>
      <v-container class="container container--fluid"> 
        
<!--------------------------------------------------------------------->
  <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="10000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
<v-form id="profile_form" @submit.prevent="submit" v-model="isValid">
  <v-row>
     <v-col cols="6" sm="12" md="3"  colored-border color="deep-purple accent-4">
       <!-- <p style="border:solid 1px">AAAA</p> -->
            <template>
              <span v-if="person_details.profile_image_path">
                <v-img
                  :src="awspath+person_details.profile_image_path+person_details.profile_image_name"
                  aspect-ratio="1"
                  class="grey lighten-2"
                ></v-img>
              </span>
              <span v-else>
                 <v-skeleton-loader
                  class="mx-auto"
                  max-width="300"
                  type="image"
                ></v-skeleton-loader>
              </span>
            </template>
                <v-col cols="12" sm="12" md="12">
                  <v-file-input
                    :rules="rules"
                    :accept="file_upload_config.allowed_type"
                    placeholder="(max size: 2MB)"
                    prepend-icon="mdi-camera"
                    label="Profile Image"
                    show-size
                    v-model="ProfileImage"
                  ></v-file-input>
                </v-col>
              <!-- <v-col cols="12" sm="6" md="12"  class="pt-0 pb-0">
                <v-file-input accept="image/*" label="Select image"></v-file-input>
              </v-col>     -->
              <v-col cols="12" sm="6" md="12"  class="pt-0 pb-0">
                <v-text-field  v-model="person_details.firstname" name="firstname" label="First name" class="pt-0"></v-text-field> 
              </v-col>    
              <v-col cols="12" sm="6" md="12"  class="pt-0 pb-0">   
                <v-text-field  v-model="person_details.middlename" name="middlename" label="Middle name" class="pt-0"></v-text-field>   
              </v-col>
              <v-col cols="12" sm="6" md="12"  class="pt-0 pb-0">          
                <v-text-field  v-model="person_details.lastname" name="lastname" label="Last name"  class="pt-0"></v-text-field>
              </v-col>

     </v-col>
     <!-- ##########################@@@@@@@@@@@@@@@@@@@@@@@@%%%%%%%%%%%%%%%%%%%%%%%%%%% -->
     <v-col cols="6" sm="6" md="9"  colored-border color="deep-purple accent-4">
        <!-- <p style="border:solid 1px">AAAA</p> -->
       
          <v-row>
<v-col cols="12" sm="12" md="12"  colored-border color="deep-purple accent-4" class="pt-0 pb-0">
    <!-- <p class="subtitle1">Personal Information</p> -->
    <v-alert
      border="left"
      colored-border
      color="primary"
      elevation="2"  class="pt-1 pb-1">
    Personal Information
    </v-alert> 
</v-col>
          
            <v-col cols="12" sm="6" md="6" class="pt-0 pb-0">
                <v-text-field v-model="person_details.fullname" name="fullname" label="Full Name"></v-text-field>
            </v-col>
          </v-row>
        <v-row class="personal_details" >            
            <v-col cols="12" sm="6" md="4"  class="pt-0 pb-0">
              <v-text-field v-model="person_details.mother_first_name" name="mother_first_name" label="Mother's first name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4"  class="pt-0 pb-0">
              <v-text-field v-model="person_details.father_full_name" name="father_full_name" label="Father's Full name"></v-text-field>
            </v-col>
              <v-col cols="12" sm="6" md="4"  class="pt-0 pb-0">
              <v-text-field v-model="person_details.highest_qualification" name="highest_qualification" label="Highest Qualification"></v-text-field>
            </v-col>            
        </v-row><!--personal details close-->
        <v-row class="birth_details">
<v-col cols="12" sm="12" md="12"  colored-border color="deep-purple accent-4"  class="pt-0 pb-0">
    <!-- <p class="subtitle1">Personal Information</p> -->
    <v-alert
      border="left"
      colored-border
      color="primary"
      elevation="2" class="pt-1 pb-1">
    Birth Details:
    </v-alert> 
</v-col>
         
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
           <template v-slot:activator="{ on }">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
              v-model="person_details.date_of_birth"
              @blur="date = parseDate(date_of_birth)"
               persistent-hint
               v-on="on"
              name="date_of_birth"
              label="Date of Birth"
              prepend-icon="event"
              
              ></v-text-field>
              </v-col> 
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu1 = false"
            ></v-date-picker> 
            </v-menu>  
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="person_details.birth_place" name="birth_place" label="Birth Place"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-textarea
                v-model="person_details.birth_address"
                label="Birth Address"
                name="birth_address"
                rows="1"
                hint="Main street,London"
              ></v-textarea>
            </v-col>
        </v-row><!--Birth details close-->
        <v-row class="contact_details">
 <v-col cols="12" sm="12" md="12"  colored-border color="deep-purple accent-4" class="pt-0 pb-0">
    <!-- <p class="subtitle1">Personal Information</p> -->
    <v-alert
      border="left"
      colored-border
      color="primary"
      elevation="2" class="pt-1 pb-1">
    Contact Details:
    </v-alert> 
</v-col>
            <v-col cols="12" sm="6" md="4"  class="pt-0 pb-0">
              <v-text-field 
              id="mobile_no_for_otp"
              type="number" 
              v-model="person_details.mobile_no" 
              name="mobile_no" label="Mobile No." 
               :rules="[v => v.length == 10 || 'enter 10 digits']"  
              counter="10" 
              @keyup='remaincharCount()'
              >
              <v-btn slot="append"  v-on="on"  @click="dialog=true; sendotp(); " 
               tile small color="primary" id="verify_otp_btn">Verify</v-btn>
              </v-text-field>
              
              <span style="color:green;" id="verified_msg" class="text-capitalize text--success"> This number is verified.</span>              
              <!----------------------------------------------------------------------------------->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline" style="display:none;">Test</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <p>OTP has been sent on {{person_details.mobile_no}} </p>
              <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                <v-text-field v-model="mobile_otp" label="Enter OTP" outlined></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                 <v-btn text small  @click="resend_otp();" 
                 class="pt-0" style="float: left">
                  Resend OTP
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="pt-0 pb-0">
                <v-btn color="primary darken-1" @click="verify_otp(); dialog=false" class="pt-0" style="float: right">
                  Verify number
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!----------------------------------------------------------------------------------->

              <!-- <div id="otp_div">
              <p>OTP has been sent on this no.</p>
               <v-text-field type="number" placeholder="enter OTP"></v-text-field>
               <v-btn tile small color="primary" @click="verify_otp()">Verify number </v-btn>
              </div> -->
            </v-col>
          
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-text-field  
              type="number" 
              counter="10" 
              :rules="[v => v.length == 10 || 'enter 10 digits']" 
              v-model="person_details.alternate_mobile_no" 
              name="alternate_mobile_no" 
              label="Alternate Mobile No."></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-text-field  type="number" counter="10" v-model="person_details.telephone_no" name="telephone_no" label="Telephone No."></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-text-field v-model="person_details.emergency_contact_name" name="emergency_contact_name" label="Emergency Contact Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-text-field  type="number" counter="10" v-model="person_details.emergency_contact_number" name="emergency_contact_number" label="Emergency Contact No."></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-textarea
                v-model="person_details.emergency_contact_address"
                label="Emergency Contact Address"
                name="emergency_contact_address"
                rows="1"
                hint="Main street,London"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-text-field type="text" v-model="person_details.personal_email" name="personal_email" label="Personal Email"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-text-field type="text" v-model="person_details.institute_email" name="institute_email" label="Institute Email"></v-text-field>
            </v-col>    
        </v-row><!--Contact details row closing-->
        <v-row class="identity_details">
<v-col cols="12" sm="12" md="12"  colored-border color="deep-purple accent-4"  class="pt-0 pb-0">
    <!-- <p class="subtitle1">Personal Information</p> -->
    <v-alert
      border="left"
      colored-border
      color="primary"
      elevation="2"  class="pt-1 pb-1">
    Identity Details:
    </v-alert> 
</v-col>         
           
            <v-col cols="12" sm="6" md="4"  class="pt-0 pb-0">
              <v-text-field  v-model="person_details.aadhar_no" name="aadhar_no" label="Aadhar No."></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
              <v-text-field  v-model="person_details.pan_no" name="pan_no" label="PAN No."></v-text-field>
            </v-col>            
        </v-row><!-- Identity_details row closing-->
        <v-row class="medical_details">
<v-col cols="12" sm="12" md="12"  colored-border color="deep-purple accent-4" class="pt-0 pb-0">
    <!-- <p class="subtitle1">Personal Information</p> -->
    <v-alert
      border="left"
      colored-border
      color="primary"
      elevation="2"  class="pt-1 pb-1">
    Medical Details:
    </v-alert> 
</v-col>      
          
           <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
               <v-text-field v-model="person_details.medical_problem" name="medical_problem" label="Medical Problem"></v-text-field>
            </v-col>  
            <!-- <v-col cols="12" sm="6" md="4"  class="pt-0 pb-0">
              <label> Physically Handicapped? </label>
              <v-radio-group v-model="person_details.isphysicallyhandicapped" name="isphysicallyhandicapped" row class="mt-0"> 
                <v-radio label="Yes" value="radio-1"></v-radio>
                <v-radio label="NO" value="radio-2"></v-radio> 
              </v-radio-group>
            </v-col> -->
            <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
               <v-textarea
                  v-model="person_details.physicallyhandicapped_details"
                  label="physically handicapped details"
                  rows="1"
                  name="physicallyhandicapped_details"
                  hint="physically handicapped details"
                ></v-textarea>
            </v-col>  
            
        </v-row><!-- medical_details row closing-->
         <v-row>
        <v-card-actions>
           <v-btn :color="isValid ? 'primary darken-1' : 'grey lighten-1'"
              @click="submit"
              :disabled="!isValid"
              >Save</v-btn>
          <v-btn color="secondary darken-1"  @click="close">Cancel</v-btn>
        </v-card-actions>
    </v-row> 
   </v-col>
  </v-row>
</v-form>
<!--------------------------------------------------------------------->
      </v-container>
      
    </v-content>
  </v-app>
  </div>
</template>

<script>
import axios from 'axios'
import epsHeader from "../shared/Header";
export default {
  inject: ['theme'],
     data: () => ({
      user:'',
      return: {
        otp_id:"",
        mobile:"",
        mobile_otp:"",
      },
      
        maxcharacter: 10,
        remaincharactersText: "enter 10 digits.",
                   
      //max_mobile:10,  
      snackbar_msg: "",
      isValid: false,
      color: "",
      snackbar: false,
      search:'',
      dialog: false,
      menu1: false,
      file_upload_config: { allowed_type: "", max_size: 1000 },
    rules: [
      value =>
        !value || value.size < 2000000 || "Profile Image size should be less than 2 MB!"
    ],
      person_details: [],
        personid:'',
        firstname:'',
        personal_email:'',
        institute_email:'',
        middlename:'',
        lastname:'',
        fullname:'',
        highest_qualification:'',
        date_of_birth:{},
        date: new Date().toISOString().substr(0, 10),
        date_of_birth_in_words:'',
        isphysicallyhandicapped:'',
        physicallyhandicapped_details:'',
        mother_first_name:'',
        father_full_name:'',
        birth_place:'',
        birth_address:'',
        aadhar_no:'',
        pan_no:'',      
        medical_problem:'',
        mobile_no:'',
        alternate_mobile_no:'',
        telephone_no:'',
        emergency_contact_name:'',
        emergency_contact_address:'',
        emergency_contact_number:'',
        profile_image_path:'',
        profile_image_name:'',
        gender:'',
        birthcountry:'',
        bloodgroup:'',
        mothertoungue:'',
        ProfileImage:null,
    }),
  components: {
    epsHeader:epsHeader,
  },
  mounted(){
      this.onLoad();
     
    },

  watch: {
    dialog (val) {
      val || this.close()
    },
    overlay (val) {
      val= () => {
        this.overlay = false
      };
    },
    date(val) {
      this.person_details.date_of_birth = this.formatDate(this.date);
    }
  },

  methods:{
    verify_otp: function(){
        const data={
          mobile:this.person_details.mobile_no,
          email:this.person_details.personal_email,
          mobile_otp:this.mobile_otp,
          personid:this.person_details.personid
        }
        //console.log(data)
        axios
        .post("/Login/verifyOtpForMobile", data)
        .then(res => {
          if (res.data.status == "SUCCESS") {
            console.log(res)
              //this.person_details.push(this.formData);
              this.showSnackbar("#4caf50", "Details Updated Successfully..."); // show snackbar on success
               document.getElementById("verify_otp_btn").style.display = "none",
               document.getElementById("verified_msg").style.display = "block",
      //           disablemobiletext(){
      //     document.getElementById("mobile_no_for_otp").disabled = true;
      // },
              document.getElementById("mobile_no_for_otp").setAttribute("disabled", "true");
              document.getElementById("mobile_no_for_otp").style.color= "grey"
            }
            else if (res.data.status.code == "FAIL") {
               this.showSnackbar("#b71c1c", "OTP Doesn't match, Please verify again!");
             }
        })
        .catch(error => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          //this.fetch_btn_load = false;
        });
    },

      sendotp: function(){
        //alert('inside send otp function');
        const data={
          mobile:this.person_details.mobile_no,
          email:this.person_details.personal_email
        }
        //console.log(data)
        axios
        .post("/Login/sendOtpForMobile", data)
        .then(res => {
          if (res.data.msg == "200") {
            if (res.data.status.code == "SUCCESS") {
              //this.person_details.push(this.formData);
              this.showSnackbar("#4caf50", "Details Updated Successfully..."); // show snackbar on success
             
            }
            else if (res.data.status.code == "FAIL") {
               this.showSnackbar("#b71c1c", "OTP Doesn't match, Please verify again!");
             }
          } 
        })
        .catch(error => {
          window.console.log(error);
        })
        .finally(() => {
          //close loader
          //this.fetch_btn_load = false;
        });
      },
     
      remaincharCount: function(){
        document.getElementById("verify_otp_btn").style.display = "none";
         if(this.person_details.mobile_no.length == this.maxcharacter){
           this.remaincharactersText = "equal "+this.maxcharacter+" characters limit.";
           document.getElementById("verify_otp_btn").style.display = "block";
         }else{
           var remainCharacters = this.maxcharacter !== this.person_details.mobile_no.length;
           this.remaincharactersText = "enter valid characters.";
          document.getElementById("verify_otp_btn").style.display = "none";
           //document.getElementById("otp_div").style.display = "none";
         // this.remaincharactersText.style.color="red";
         }
       },
     submit: function(e) {
       if (this.ProfileImage != null) {
        if (this.ProfileImage.size > 2000000) {
          this.showSnackbar("#b71c1c", "File size exceeded!!!");
          return;
        }
      }
      var form = document.getElementById("profile_form");
      var formData = new FormData(form);
      formData.append("ProfileImage", this.ProfileImage);
        axios
          .post("/Profile/editProfile", formData,{
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
          .then(res => {
            if (res.data.status.code == "SUCCESS") {
              //this.person_details.push(this.formData);
              this.showSnackbar("#4caf50", "Details Updated Successfully..."); // show snackbar on success
              this.onLoad();
            }
             else if (res.data.status.code == "FAIL") {
               this.showSnackbar("#b71c1c", "Person Not Found in System!!!");
             }
          })
          .catch(error => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            window.console.log(error);
          });
        this.close();
      },

      onLoad(){
      this.overlay=true;
      this.awspath = localStorage.getItem("awspath");
      axios
      .post("/Profile/profile")
      .then(res => {
        if (res.data.msg == "200") {
          if (res.data.status.code == "SUCCESS"){
            if(this.load = true){
              this.overlay=false;
                this.person_details = res.data.person_details;
                this.file_upload_config = res.data.file_upload_config;
                this.rules = [
                  value =>
                    !value ||
                    value.size < this.file_upload_config.max_size ||
                    "Profile Image size should be less than " +
                      this.file_upload_config.max_size / 1000000 +"MB!"
                ];
            }
          }else if(res.data.status.code == "NA"){
            this.showSnackbar("#b71c1c", "You cannot upadate profile from Here!..."); // show snackbar
              document.getElementById("profile_form").style.pointerEvents = "none",
            window.console.log(res.data.status.code);
          }
        } else {
        }
      })
      .catch(error => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        window.console.log(res.data.status.code);
      })
      .finally(()=>{ 
        'use strict';
        var overlay=false;
      });
      },
      showSnackbar(clr, msg) {
        this.snackbar = true;
        this.color = clr;
        this.snackbar_msg = msg;
      },

      formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


      }, // end of save()
    }  //end of methods
</script>


<style scoped>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
.v-icon-size{
  font-size: 20px;
}
.edit-avatar{
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover{
  background-color: #b0bec5;
}
.edit-v-icon{
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover{
  color: white;
}
#otp_div{
  display: none;
}
#verify_otp_btn,#verified_msg{
  display: none;
}
</style>